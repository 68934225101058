//
// Perch Core Scripts
// ----------------------------------------------------------------------------


// Feature Detects
// --------------------------------------------------

// Passive option for event listeners
// ---------------------------------------
// Feature detection for addEventListener's 'passive' option
// ---------------------------------------
var passiveEventListenerOptionSupported = false;

try {
	var opts = Object.defineProperty({}, "passive", {
		get: function() {
			passiveEventListenerOptionSupported = true;
		}
	});
	window.addEventListener("testPassiveOption", null, opts);
	window.removeEventListener("testPassiveOption", null, opts);
} catch(err) {}


// Default Fixes / Resets
// --------------------------------------------------

// Remove the scrolling increment/decrement behavior from number input elements
document.addEventListener("mousewheel", function(event) {
	if(document.activeElement.type === "number"){
		document.activeElement.blur();
	}
}, passiveEventListenerOptionSupported ? { passive: true } : false);


// Exclude hidden elements from Parsley
if (typeof Parsley !== 'undefined' && typeof Parsley.options !== 'undefined') {
	Parsley.options.excluded = 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden';
}



// Enhancements, Initializations, etc.
// --------------------------------------------------

// Loading notifications
// ---------------------------------------
// When the page is loaded, add & remove classes from the body element
// ---------------------------------------
// H/t to https://www.sitepoint.com/jquery-document-ready-plain-javascript/
// ---------------------------------------
var domLoadedCallback = function(){
	document.body.classList.add('dom-has-loaded');
	document.body.classList.remove('dom-not-yet-loaded');
};

if ( document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll) ) {
	domLoadedCallback();
} else {
	document.addEventListener("DOMContentLoaded", domLoadedCallback);
}



// Perch Global Variables
// ---------------------------------------
// Regardless of the JS used to control or assist navigation/UI/etc., these are the standardized global variable names, with the standardized markup IDs and classes used for selection.
// ---------------------------------------
var $global_header 			= $('#global-header'),

	$nav_outer_wrapper 		= $('#global-outer-navigation-wrapper'),

	$nav_reveal_button 		= $('.hamburger-menu-button'),

	$nav_parent 			= $('.global-nav-item--has-children'),
	$nav_active_parent 		= $('.global-nav-item--has-children.active-parent'),
	$nav_parent_link 		= $('.global-nav-item--has-children > .global-nav-link'),
	$nav_child 				= $('.global-nav-child-list'),

	$main_nav 				= $('#global-main-nav'),
	$main_nav_parent 		= $('.global-main-nav-list > .global-nav-item--has-children'),

	// ⏩⏩⏩ MOVED ⏩⏩⏩
	// Moved to:
	//  - their own individual Library files:
	// 		child-nav-open-with-parent-click.js
	// 		child-nav-open-with-generated-button.js
	// > > > > > > > > > > > > > > > > > > > > > > > >
	// $nav_link_opens_child 	= $('.global-nav-item--opens-child > .global-nav-link'),
	// $nav_parent_has_button 	= $('.global-nav-item--has-child-nav-opener'),
	// < < < < < < < < < < < < < < < < < < < < < < < <
	// end moved code ⏩⏩⏩

	$utility_nav 			= $('#global-utility-nav'),

	// ⏩⏩⏩ MOVED ⏩⏩⏩
	// Moved to:
	//  - the modal-search-system.js Library file
	// > > > > > > > > > > > > > > > > > > > > > > > >
	// $search_reveal_button	= $('.global-search-reveal-button'),
	// $search_menu 			= $('#global-search-overlay'),
	// $search_input 			= $('#global-search-overlay__input'),
	// $search_submit 			= $('#global-search-overlay__submit'),
	// $search_close_btn 		= $('#global-search-overlay__close-button'),
	// < < < < < < < < < < < < < < < < < < < < < < < <
	// end moved code ⏩⏩⏩


	// Global standard for triggering search.
	// Example usage: rather than embed an additional search form input and submit on a 404 page, add a link or button with this class that summons the site search.
	// If not using functionality in the modal search system, write something similar using this class name and variable.
	$search_trigger 		= $('.global-search-trigger');

var nav_close_timer;
