//
// Child Nav Open With Parent Click (Modified from Perch's version)
// --------------------------------------------------
// If a nav link that has a child list as a sibling
// is clicked, open the child menu.
// --------------------------------------------------
// perch-core-scripts.js required.
// Do not use alongside child-nav-open-with-generated-button.js
// --------------------------------------------------


// Variables
// ---------------------------------------
// Only select Links that are previous siblings to child lists that are second-level lists
var $nav_link_opens_child 		= $('.global-main-nav-list > .global-main-nav-item > .global-nav-child-list').prev('.global-nav-link');
// ---------------------------------------


// Logic
// ---------------------------------------
$nav_link_opens_child.click(function(e) {
	// the viewport is at below 767 pixels wide, perform these actions. 
	// *** NOTE ***
	// *** *** *** ***
	// This value, while hardcoded, does correspond to the site's @mobile-styles-max value, which is set by a custom value for @desktop-styles-min minus one. This value is set in /assets/less/_site-global-variables.less. Make sure to keep the value below in sync with @mobile-styles-min !
	// *** *** *** ***
	// *** NOTE ***
	if (window.matchMedia("(max-width: 767px)").matches) {
		// Prevent link from being followed
		e.preventDefault();

		// set the parent
		var $the_parent = $(this).parent();

		// remove is-open class if it's already there
		if ( $the_parent.hasClass('submenu-is-open') || $the_parent.hasClass('submenu-is-mobile-open') ) {
			$the_parent.removeClass('submenu-is-open submenu-is-mobile-open')
				.children('.global-nav-child-list').removeClass('is-open');
			$(this).blur();
		}
		// otherwise, add it
		else {
			$the_parent.addClass('submenu-is-open')
				.children('.global-nav-child-list').addClass('is-open');
		}
	}
});
