//
// Video element autoplay fallback
// --------------------------------------------------
// Needed for instances where the user has a setting
// or preference that prevents videos from autoplaying
// (e.g. iOS' Low Power Mode)
// --------------------------------------------------
// For more information on how this works, see:
//  - https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play
// --------------------------------------------------

// first, detect native support for Promises
// via https://stackoverflow.com/a/22517133/4486857
if(typeof Promise !== "undefined" && Promise.toString().indexOf("[native code]") !== -1){
	// console.log("Promises supported");

	// declare variables & set up promise if the video is found
	var autoplayVideo 	= document.querySelector('#home-hero-video');
	var videoContainer 	= document.querySelector('.home-hero-video-container');

	// if these two elements are found, continue with the JS
	if (autoplayVideo && videoContainer) {
		var playPromise 	= autoplayVideo.play();

		// create the fallback photo
		var fallbackPhoto	= document.createElement('img');
		fallbackPhoto.className = "home-hero-video-fallback-photo home-hero-video";
		fallbackPhoto.setAttribute("src", "/assets/images/bg-home-hero--fallback-photo.jpg");
		fallbackPhoto.setAttribute("alt", "");
		fallbackPhoto.setAttribute("role", "presentation");

		if (playPromise !== undefined) {
			playPromise
				// // removed fat arrow because it causes an IE11 syntax error
				// // .then(_ => {
				// .then(function() {
				// 	console.log('Autoplay working');
				// })

				// removed fat arrow because it causes an IE11 syntax error
				// .catch(error => {
				.catch( function(error) {
					// console.log('Autoplay was prevented');

					autoplayVideo.classList.add('is-not-autoplaying');

					videoContainer.appendChild(fallbackPhoto);
				});
		}
	}
}
// else {
// 	console.log("Promises NOT supported");
// }
